import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for programmatic navigation
import "./Footer.css";

const Footer = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    if (searchTerm.trim()) {
      // Navigate to the search page with the search term as a query parameter
      navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
    }
  };

  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* Footer Text */}
        <div className="footer-text">
          <p>© 2024 LEaPP Lab. All rights reserved.</p>
        </div>

        {/* Search Bar
        <form className="footer-search" onSubmit={handleSearchSubmit}>
          <input
            type="text"
            placeholder="Search this site"
            className="footer-search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button type="submit" className="footer-search-button">
            🔍
          </button>
        </form> */}
      </div>
    </footer>
  );
};

export default Footer;
