import React from "react";
import "./HeroSection.css";

const HeroSection = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        {/* <h1></h1> */}
        <p className="lab-name">
          <span className="lab-acronym">Welcome</span>
          <span className="lab-acronym">LEaPP</span>
          <span className="lab-explanation">
            Language Education and Positive Psychology Lab
          </span>
        </p>
      </div>
    </section>
  );
};

export default HeroSection;
