// src/pages/Research.js
import React from "react";
import {
  FaBook,
  FaUsers,
  FaMicroscope,
  FaGlobe,
  FaTasks,
  FaGraduationCap,
} from "react-icons/fa";
import "./Research.css";

const Research = () => {
  return (
    <div className="research-container">
      <h1 className="section-title">Our Research</h1>

      <section>
        <h2 className="sub-section-title">Research Focus Areas</h2>
        <ul className="research-list">
          <li>
            <FaBook className="list-icon" /> Integrating positive psychology
            principles and interventions into language curriculum and
            instruction
          </li>
          <li>
            <FaUsers className="list-icon" /> Examining the role of positive
            emotions, character strengths, resilient and growth mindsets in
            language learning
          </li>
          <li>
            <FaMicroscope className="list-icon" /> Evaluating the impact of
            social-emotional learning on academic achievement
          </li>
          <li>
            <FaTasks className="list-icon" /> Testing strengths-based
            pedagogical approaches for diverse language learners
          </li>
          <li>
            <FaGlobe className="list-icon" /> Exploring the relationship between
            multilingualism, multi-dimensional benefits, and student flourishing
          </li>
        </ul>
      </section>

      <section>
        <h2 className="sub-section-title">Key Activities</h2>
        <ul className="key-activities-list">
          <li>
            <FaMicroscope className="list-icon" /> Empirical studies and
            longitudinal research projects
          </li>
          <li>
            <FaTasks className="list-icon" /> Development of evidence-based
            language learning resources
          </li>
          <li>
            <FaUsers className="list-icon" /> Collaborations with educators,
            school districts, and community partners
          </li>
          <li>
            <FaGlobe className="list-icon" /> Dissemination of findings through
            publications, conferences, and consultations
          </li>
          <li>
            <FaGraduationCap className="list-icon" /> Providing training and
            workshops to education professionals
          </li>
        </ul>
      </section>

      <section className="prospective-students">
        <h2 className="prospective-title">Prospective Students</h2>
        <p>
          The lab director is available to advise new doctoral students and
          recruit research assistants. If you’re interested, please{" "}
          <a href="/contact" className="contact-link">
            contact us
          </a>
          .
        </p>
      </section>
    </div>
  );
};

export default Research;
