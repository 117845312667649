import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import schoolLogo from "../assets/CUHK_PURPLE_WHITE_LG.PNG"; // Import the school logo
import "./Navbar.css"; // Ensure this CSS file is linked correctly

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="navbar">
      <div className="navbar-container">
        {/* School Logo */}
        <div className="navbar-school-logo">
          <a
            href="https://www.cuhk.edu.hk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={schoolLogo}
              alt="CUHK School Logo"
              className="school-logo"
            />
          </a>
        </div>

        {/* Hamburger Menu */}
        <div className="hamburger" onClick={toggleMenu}>
          &#9776; {/* Hamburger Icon */}
        </div>

        {/* Navigation Links */}
        <nav className={`navbar-right ${isOpen ? "open" : ""}`}>
          <NavLink to="/" exact className="nav-link" activeClassName="active">
            Home
          </NavLink>
          <NavLink to="/about" className="nav-link" activeClassName="active">
            About
          </NavLink>
          <NavLink to="/research" className="nav-link" activeClassName="active">
            Research
          </NavLink>
          <NavLink to="/people" className="nav-link" activeClassName="active">
            People
          </NavLink>
          <NavLink to="/contact" className="nav-link" activeClassName="active">
            Contact
          </NavLink>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
