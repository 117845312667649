// src/pages/About.js
import React from "react";
import "./About.css"; // Make sure this CSS file is linked correctly

const About = () => {
  return (
    <div className="about-container">
      <h1 className="section-title">About Us</h1>

      {/* Mission and Vision Section */}
      <section className="about-section">
        <h2 className="sub-section-title">Mission and Vision</h2>
        <p>
          The LEaPP lab is dedicated to conducting interdisciplinary research
          that explores the intersection of language learning, positive
          psychology, and educational sciences. Our mission is to investigate
          how principles of positive psychology can be applied to enhance
          language learning, teaching practices, and student outcomes.
        </p>
        <p>
          The LEaPP lab aims to advance language education by integrating
          positive psychology and well-being principles, optimizing learning
          experiences and supporting students’ holistic development.
        </p>
      </section>

      {/* What We Do Section */}
      <section className="about-section">
        <h2 className="sub-section-title">What We Do</h2>
        <p>
          Our lab conducts research, program evaluations, and collaborates with
          educators and communities to develop tools and resources that
          integrate positive psychology into language learning.
        </p>
        <p>
          For more information on specific projects, visit our{" "}
          <a href="/research">Research</a> page.
        </p>
      </section>
    </div>
  );
};

export default About;
