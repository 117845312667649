// src/pages/Home.js
import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import HeroSection from "../components/HeroSection";
import "./Home.css"; // Ensure this CSS file is linked correctly

const Home = () => {
  return (
    <div className="home-container">
      {/* Hero Section */}
      <HeroSection />

      {/* Who We Are Section */}
      <section className="about-section">
        <h2 className="section-title">Who We Are</h2>
        <div className="about-cards">
          <div className="about-card">
            <h3 className="card-title">
              <Link to="/people" className="card-link">
                Dr. Xinjie Chen ›
              </Link>
            </h3>
            <p>
              Dr. Xinjie Chen is an Assistant Professor at the Chinese
              University of Hong Kong. She leads the LEaPP Lab, focusing on
              research in language education and positive psychology.
            </p>
          </div>
          <div className="about-card">
            <h3 className="card-title">
              <Link to="/people" className="card-link">
                Meet the Team ›
              </Link>
            </h3>
            <p>
              At the LEaPP Lab, we explore how language education and positive
              psychology can work together to enhance learning and well-being.
              Learn more about our multi-disciplinary team of researchers.
            </p>
          </div>
        </div>
      </section>

      {/* Recent Updates Section */}
      <section className="recent-updates-section">
        <h2 className="section-title">Recent Updates</h2>
        <div className="recent-updates-container">
          <div className="update-item placeholder-update">
            <h3>Stay Tuned!</h3>
            <p>
              Check back later for updates on our latest projects and
              publications.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
